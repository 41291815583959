import Vue from 'vue'
import Router from 'vue-router';
import Home from "@/Home";
import ShowNews from "@/ShowNews";

// News Routes
import NewsEntries from '../static/news.json'

const newsRoutes = Object.keys(NewsEntries).map(section => {
    const childrenDe = NewsEntries[section].map(child => ({
        path: 'de/' + child.id,
        name: child.id + '-de',
        component: () => import(`../news/${section}/${child.id}.de.md`)
    }));
    const childrenFr = NewsEntries[section].map(child => ({
        path: 'fr/' + child.id,
        name: child.id + '-fr',
        component: () => import(`../news/${section}/${child.id}.fr.md`)
    }));

    let children = childrenDe.concat(childrenFr);

    console.log(children);

    return {
        path: `/news/${section}`,
        name: 'news.show',
        component: ShowNews,
        children
    }
})


Vue.use(Router);



let router = new Router({
    linkExactActiveClass: 'active',
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {
                title: 'Home'
            },
            component: Home,
        },
        ...newsRoutes
    ]
});


router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title + ' - GECAMed';

    return next();
});


export default router;
