<template>
  <section id="home" class="bg-white ">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8 pt-2 pb-20">

      <section-heading :title="$t('pictures.catchphrase')">
        {{ $t('pictures.subtext') }}
      </section-heading>

      <div class="grid grid-cols-1 gap-4 gap-y-16 min-h-screen ">
        <div class="shadow-xl">
          <img src="../../assets/img/doctena_integration.png" alt="" class="inset-0 w-full h-full object-fill bg-gray-100 rounded-md" />
        </div>
        <div class="shadow-xl">
          <img src="../../assets/img/administrative_screenshot.png" alt="" class="inset-0 w-full object-fill bg-gray-100 rounded-md" />
        </div>
        <div class="shadow-xl">
          <img src="../../assets/img/dicom_screenshot.png" alt="" class="inset-0 w-full object-fill bg-gray-100 rounded-md" />
        </div>
      </div>


    </div>
  </section>
</template>

<script>
import SectionHeading from "@/components/partials/SectionHeading";
export default {
  name: "Pictures",
  components: {SectionHeading}
}
</script>

<style scoped>

</style>