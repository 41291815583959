<template>
  <section id="news" class="bg-gray-50 mt-auto">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8 pt-2 pb-20">
      <section-heading :title="$t('news.title')">
        {{ $t('news.subtext') }}
      </section-heading>

      <div class="grid grid-cols-1 gap-x-16 gap-y-8 center-align" :class="{'md:grid-cols-2': currentNewsChunk.length > 1}">
        <news-card
            :id="news.id"
            :title="news['title_' + $i18n.locale]"
            :date="news.date"
            :description="news['description_' + $i18n.locale]"
            v-for="news in currentNewsChunk" :key="news.id"></news-card>
      </div>

      <div class="flex mt-16 text-center select-none">
        <div class="flex-1">
          <a :class="[hasPreviousPage ? 'text-blue-500 hover:underline cursor-pointer' : 'text-gray-700']"
             @click="previousPage">&laquo; {{ $t('news.newer') }}</a>
        </div>
        <div class="flex-1 flex items-center">
          <div class="w-3 h-3 cursor-pointer rounded-3xl bg-gray-300 mx-auto flex-shrink-0" :class="[key +1  === currentPage ? 'border border-gray-400 shadow-md' : ''] " v-for="(elem, key) in newsPages" :key="elem.id"
               @click="currentPage = (key + 1)"></div>
        </div>
        <div class="flex-1">
          <a :class="[hasNextPage ? 'text-blue-500 hover:underline cursor-pointer' : 'text-gray-700']"
             @click="nextPage">{{ $t('news.older') }} &raquo;</a>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import SectionHeading from "@/components/partials/SectionHeading";
import NewsCard from "../partials/news/NewsCard";
import NewsEntries from '../../static/news.json'

export default {
  name: "News",
  components: {NewsCard, SectionHeading},
  data: () => ({
    currentPage: 1
  }),
  methods: {
    nextPage() {
      if (!this.hasNextPage)
        return false;

      this.currentPage =  this.currentPage + 1;
    },
    previousPage() {
      if (!this.hasPreviousPage)
        return false;

      this.currentPage = this.currentPage - 1;
    },
    chunk(array, size) {
      const chunked_arr = [];
      for (let i = 0; i < array.length; i++) {
        const last = chunked_arr[chunked_arr.length - 1];
        if (!last || last.length === size) {
          chunked_arr.push([array[i]]);
        } else {
          last.push(array[i]);
        }
      }
      return chunked_arr;
    }
  },
  computed: {
    hasNextPage() {
      return this.currentPage < this.newsPages;
    },
    hasPreviousPage() {
      return this.currentPage !== 1;
    },
    currentNewsChunk() {
      return this.chunkedNewEntries[this.currentPage -1];
    },
    chunkedNewEntries() {
      return this.chunk(this.allNewsEntries, 4);
    },
    allNewsEntries() {
      return Object.keys(NewsEntries).flatMap(x => NewsEntries[x]);
    },
    newsPages() {
      return this.chunkedNewEntries.length;
    }
  }
}
</script>

<style scoped>

</style>