<template>
  <section id="benefits" class="text-gray-900 pb-20">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8 pt-2 pb-20">

      <section-heading :title="$t('benefits.title')">
        {{ $t('benefits.subtext') }}
      </section-heading>


      <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
        <benefit-card :title="$t('benefits.benefit_multilang_title')">
          {{ $t('benefits.benefit_multilang') }}
        </benefit-card>
        <benefit-card :title="$t('benefits.benefit_multiplatform_title')">
          {{ $t('benefits.benefit_multiplatform') }}
        </benefit-card>
        <benefit-card :title="$t('benefits.benefit_doctena_title')">
          {{ $t('benefits.benefit_doctena') }}
        </benefit-card>
        <benefit-card :title="$t('benefits.benefit_a_to_z_title')">
          {{ $t('benefits.benefit_a_to_z') }}
        </benefit-card>
        <benefit-card :title="$t('benefits.benefit_voice_recognition_title')">
          {{ $t('benefits.benefit_voice_recognition') }}
        </benefit-card>
        <benefit-card :title="$t('benefits.benefit_updates_title')">
          {{ $t('benefits.benefit_updates') }}
        </benefit-card>
      </div>
    </div>
  </section>
</template>

<script>
import BenefitCard from "@/components/partials/benefits/BenefitCard";
import SectionHeading from "@/components/partials/SectionHeading";
export default {
  name: "Benefits",
  components: {SectionHeading, BenefitCard}
}
</script>

<style scoped>

</style>